import {
	PropsWithChildren
} from 'react'

interface ContentProps {
	className?: string;
}

const Content = ({
	children, className
}: PropsWithChildren<ContentProps>) => (
	<main
		className={className}
		id="wrap-content"
	>
		{children}
	</main>
)

export default Content
