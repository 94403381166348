import Button from '@dg/common/components/Button'
import Link from '@dg/common/components/Link'
import FooterTabLiAsyncView from '@dg/common/components/Mobile/Footer/FooterView/FooterTab/FooterTabView/FooterTabLiAsyncView'
import {
	ReactNode
} from 'react'

interface FooterTabLiViewProps {
	icon: ReactNode;
	link?: string;
	name: string;
	onClick?: () => void;
	selected?: boolean;
}

const FooterTabLiView = ({
	name, icon, link, selected, onClick
}: FooterTabLiViewProps) => (
	<li
		className="relative z-10 flex h-full flex-1 items-center justify-center"
	>
		{
			link !== undefined ?
				(
					<Link
						href={link}
						className={
							`
								flex size-full flex-col items-center justify-end pb-1.5
								${
									selected !== undefined && (
										selected === true ?
											`[&>svg>g>path]:fill-[currentColor] [&>svg>path]:fill-[currentColor]` :
											`[&>svg>g>path]:fill-none [&>svg>path]:fill-none`
									)
								}
							`
						}
					>
						{
							(name === `お気に入り` || name === `MY`) && (
								<>
									{
										name === `お気に入り` && (
											<FooterTabLiAsyncView
												type="wish"
											/>
										)
									}

									{
										name === `MY` && (
											<FooterTabLiAsyncView
												type="myinfo"
											/>
										)
									}
								</>
							)
						}

						{icon}

						<span
							className="-mt-0.5 text-3xs text-dg-900 dark:text-dgdark-900"
						>
							{name}
						</span>
					</Link>
				) :
				(
					<Button
						onClick={onClick}
						className={
							`
								flex size-full flex-col items-center justify-end pb-1.5
								${
									selected === true ?
										`[&>svg>g>path]:fill-[currentColor] [&>svg>path]:fill-[currentColor]` :
										``
								}
							`
						}
					>
						{
							name === `最近見た商品` && (
								<FooterTabLiAsyncView
									type="viewed"
								/>
							)
						}

						{icon}

						<span
							className="-mt-0.5 text-3xs"
						>
							{name}
						</span>
					</Button>
				)
		}
	</li>
)

export default FooterTabLiView
