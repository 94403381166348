type PathsDataProps = Record<string, string>

const pathsData: PathsDataProps = {
	agreement: `${process.env.legacyMUrl}/gmkt.inc/Company/UserAgreement.aspx`,
	androidApp: `market://details?id=net.giosis.shopping.jp`,
	cart: `${process.env.legacyMUrl}/gmkt.inc/Mobile/Order/Cart.aspx`,
	company: `${process.env.legacyMUrl}/gmkt.inc/Company/AboutCompany.aspx`,
	cookiePolicy: `${process.env.legacyMUrl}/gmkt.inc/Mobile/Company/CookiePolicy.aspx`,
	creditHistory: `${process.env.legacyMUrl}/gmkt.inc/Mobile/My/CreditHistory.aspx`,
	findId: `${process.env.memberUrl}/mobile/find/id`,
	findPw: `${process.env.memberUrl}/mobile/find/pw`,
	funZone: `${process.env.legacyMUrl}/gmkt.inc/Mobile/Event/funZone.aspx`,
	helpFaq: `${process.env.legacyMUrl}/gmkt.inc/Mobile/CSCenter/HelpFaq.aspx`,
	home: process.env.legacyMUrl as string,
	iosApp: `itms-apps://itunes.apple.com/jp/app/qoo10-jp/id490078053`,
	join: `${process.env.memberUrl}/mobile/join/validate`,
	joinComplete: `${process.env.memberUrl}/mobile/join/complete`,
	joinInfo: `${process.env.memberUrl}/mobile/join/info`,
	joinValidateResult: `${process.env.memberUrl}/mobile/join/validateresult`,
	liveDetail: `${process.env.liveUrl}/mobile/livedetail`,
	liveError: `${process.env.liveUrl}/mobile/error`,
	livePlayer: `${process.env.liveUrl}/mobile/player`,
	liveScheduleAlarm: `${process.env.liveUrl}/mobile/schedule/alarm`,
	liveScheduleList: `${process.env.liveUrl}/mobile/schedule/list`,
	liveStudio: `${process.env.liveUrl}/mobile/studio`,
	liveViewAll: `${process.env.liveUrl}/mobile/studio/viewall`,
	login: `${process.env.memberUrl}/mobile/login`,
	loginCheckPrimaryId: `${process.env.memberUrl}/mobile/login/checkprimaryid`,
	loginConfirmEmail: `${process.env.memberUrl}/mobile/login/confirmemail`,
	loginMemberConfirm: `${process.env.memberUrl}/mobile/login/memberconfirm`,
	loginPhoneAuth: `${process.env.memberUrl}/mobile/login/phoneauth`,
	loginSns: `${process.env.memberUrl}/mobile/login/sns`,
	moveHome: `${process.env.legacyMUrl}/gmkt.inc/Mobile/Move/Default.aspx`,
	myInfo: `${process.env.memberUrl}/mobile/myinfo/info`,
	myInfoAddress: `${process.env.memberUrl}/mobile/myinfo/address`,
	myInfoEdit: `${process.env.memberUrl}/mobile/myinfo/edit`,
	myInfoFindMap: `${process.env.memberUrl}/mobile/myinfo/findmap`,
	myInfoHome: `${process.env.legacyMUrl}/gmkt.inc/Mobile/My/Navigation.aspx`,
	myInfoLoginHistory: `${process.env.memberUrl}/mobile/myinfo/loginhistory`,
	myInfoNewAddress: `${process.env.memberUrl}/mobile/myinfo/newaddress`,
	myInfoSnsAuth: `${process.env.memberUrl}/mobile/myinfo/snsauth`,
	onedayChance: `${process.env.legacyMUrl}/gmkt.inc/Mobile/Deal/OneDayChance.aspx`,
	orderHistory: `${process.env.legacyMUrl}/gmkt.inc/Mobile/My/MyOrderHistory.aspx`,
	policy: `${process.env.legacyMUrl}/gmkt.inc/Company/PrivacyPolicy.aspx`,
	qpost: `${process.env.legacyMUrl}/gmkt.inc/Mobile/CSCenter/qpostshopping.aspx`,
	refusalNewsletter: `${process.env.legacyMUrl}/gmkt.inc/Mobile/Member/RefusalNewsLetter.aspx`,
	resign: `${process.env.memberUrl}/mobile/resign`,
	resignSnsAuth: `${process.env.memberUrl}/mobile/resign/snsauth`,
	searchList: `${process.env.legacyMUrl}/s`,
	searchListFull: `${process.env.legacyMUrl}/gmkt.inc/Mobile/Search/Default.aspx`,
	security: `${process.env.legacyMUrl}/gmkt.inc/Company/Security.aspx`,
	university: `https://article-university.qoo10.jp?utm_source=qsm&utm_medium=top&utm_content=link&utm_campaign=qoo10university`,
	viewedList: `${process.env.legacyMUrl}/gmkt.inc/Mobile/My/ViewToday.aspx`,
	wishList: `${process.env.legacyMUrl}/gmkt.inc/Mobile/My/WishList.aspx`
}

export default pathsData
