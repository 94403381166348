import Link from '@dg/common/components/Link'
import {
	PropsWithChildren
} from 'react'

interface FooterTabLiViewProps {
	last?: boolean;
	link: string;
	target?: string;
}

const FooterTabLiView = ({
	children, last = false, link, target
}: PropsWithChildren<FooterTabLiViewProps>) => (
	<li
		className={
			`
				relative
				${
					last === false ?
						`
							after:absolute after:right-0 after:top-1/2 after:h-4
							after:w-px after:-translate-y-1/2 after:bg-[#d6d6d6]
						` :
						``
				}
			`
		}
	>
		<Link
			className="whitespace-nowrap px-3"
			href={link}
			target={target}
		>
			{children}
		</Link>
	</li>
)

export default FooterTabLiView
