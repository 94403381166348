import FooterTabLiView from '@dg/common/components/Mobile/Footer/FooterView/FooterTab/FooterTabView/FooterTabLiView'
import useLayerpopup from '@dg/common/lib/hooks/useLayerpopup'
import IcMove from '@dg/common/public/icons/common/ic-move.svg'
import IcFooterMy from '@dg/common/public/icons/mobile/common/ic-footer-my.svg'
import IcFooterViewed from '@dg/common/public/icons/mobile/common/ic-footer-viewed.svg'
import IcHeart from '@dg/common/public/icons/mobile/common/ic-heart.svg'
import IcHome from '@dg/common/public/icons/mobile/common/ic-home.svg'
import IcMoveBg from '@dg/common/public/icons/mobile/common/ic-move-bg.svg'
import pathsData from '@dg/common/public/json/mobile/paths'
import dynamic from 'next/dynamic'
import {
	useTranslation
} from 'react-i18next'

const FooterTabRecent = dynamic(() => {
	const parseAppEnv = JSON.parse(process.env.appEnv ?? `{"npm_package_name":""}`) as {
		npm_package_name: `live` | `member`;
	}

	if (parseAppEnv.npm_package_name !== `member`) {
		return import(`@dg/common/components/Mobile/Footer/FooterView/FooterTabRecent/AppFooterTabRecent`)
	}

	return import(`@dg/common/components/Mobile/Footer/FooterView/FooterTabRecent/FooterTabRecent`)
})

interface FooterTabViewProps {
	tabPathChk: string;
}

const FooterTabView = ({
	tabPathChk
}: FooterTabViewProps) => {
	const {
		t
	} = useTranslation()

	const layerpopup = useLayerpopup()

	return (
		<>
			<footer
				className="fixed bottom-0 left-0 z-130 w-full pt-9"
				id="wrap-footer"
			>
				<ul
					className="relative box-content flex h-13.5 w-full items-center justify-center
						bg-dg-white transition-bgColor dark:bg-dgdark-white"
				>
					<FooterTabLiView
						link={pathsData.home}
						name={t(`홈`)}
						selected={tabPathChk !== `/` && pathsData.home?.match(tabPathChk) !== null}
						icon={
							(
								<IcHome
									className="h-8.5 flex-none"
								/>
							)
						}
					/>

					<FooterTabLiView
						link={pathsData.wishList}
						name={(t(`즐겨찾기`))}
						selected={tabPathChk !== `/` && pathsData.wishList?.match(tabPathChk) !== null}
						icon={
							(
								<IcHeart
									className="my-1 h-6 flex-none"
								/>
							)
						}
					/>

					<FooterTabLiView
						link={pathsData.moveHome}
						name={t(`코디`)}
						icon={
							(
								<>
									<IcMove
										className="absolute -top-1.5 w-12 flex-none [&>g:not(:last-child)>path]:!fill-dg-white dark:[&>g:not(:last-child)>path]:!fill-dgdark-white"
									/>

									<IcMoveBg
										className="h-18 flex-none"
									/>
								</>
							)
						}
					/>

					<FooterTabLiView
						link={pathsData.myInfoHome}
						name={t(`My`)}
						selected={tabPathChk !== `/` && pathsData.myInfo?.match(tabPathChk) !== null}
						icon={
							(
								<IcFooterMy
									className="h-8.5 flex-none"
								/>
							)
						}
					/>

					<FooterTabLiView
						name={t(`최근 본 상품`)}
						selected={layerpopup.get()[`layerpopup-recentitems`]?.open === true}
						icon={
							(
								<IcFooterViewed
									className="h-8.5 flex-none"
								/>
							)
						}
						onClick={
							() => {
								if (layerpopup.get()[`layerpopup-recentitems`]?.open === false) {
									layerpopup.open({
										id: `layerpopup-recentitems`
									})
								} else {
									layerpopup.close(`layerpopup-recentitems`)
								}
							}
						}
					/>
				</ul>
			</footer>

			<FooterTabRecent />
		</>
	)
}

export default FooterTabView
