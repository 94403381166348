
import Button from '@dg/common/components/Button'
import Link from '@dg/common/components/Link'
import HeaderInfoView from '@dg/common/components/Pc/Header/HeaderView/HeaderInfoView'
import HeaderLoginMenuView from '@dg/common/components/Pc/Header/HeaderView/HeaderLoginMenuView'
import HeaderMenu from '@dg/common/components/Pc/Header/HeaderView/HeaderMenu'
import HeaderSideBar from '@dg/common/components/Pc/Header/HeaderView/HeaderSideBar'
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import pathsData from '@dg/common/public/json/pc/paths'
import {
	XMarkIcon
} from '@heroicons/react/24/outline'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import {
	useTranslation
} from 'react-i18next'

const HeaderSearch = dynamic(() => {
	const parseAppEnv = JSON.parse(process.env.appEnv ?? `{"npm_package_name":""}`) as {
		npm_package_name: `live` | `member`;
	}

	if (parseAppEnv.npm_package_name !== `member`) {
		return import(`@dg/common/components/Pc/Header/HeaderView/HeaderSearch/AppHeaderSearch`)
	}

	return import(`@dg/common/components/Pc/Header/HeaderView/HeaderSearch/HeaderSearch`)
})

interface HeaderViewProps {
	loginMenuSelected: string;
	loginMenuVisible: boolean;
	loginSeller: boolean;
	logoutFunc: () => void;
	title: string;
	type: `empty` | `headerOnly` | `logoOnly` | `main` | `sub` | `subHeaderOnly`;
}

const HeaderView = ({
	title, type, loginMenuVisible, loginSeller, loginMenuSelected, logoutFunc
}: HeaderViewProps) => {
	const {
		t
	} = useTranslation()

	const imageValidate = useImageValidate()

	let adminChk = false

	const dgCookieName = `AdminLogin=`

	if (document.cookie.match(dgCookieName) !== null) {
		adminChk = document.cookie.split(dgCookieName)[1].split(`;`)[0] === `Y`
	}

	return (
		<>
			{
				adminChk === true && (
					<div
						className="fixed left-0 top-0 z-130 w-full bg-dg-red p-2 text-center text-sm
							text-dg-white dark:bg-dgdark-red dark:text-dgdark-white"
					>
						{t(`현재 Admin을 통해 귀하의 계정에 로그인되어 있습니다. 고객 정보를 변경하거나 문자를 입력하지 않도록 주의하십시오.`)}
					</div>
				)
			}

			{
				type !== `empty` && (
					<header
						id="wrap-header"
						className="sticky left-0 top-0 z-120 mb-2.5 w-full min-w-pc space-y-2.5 bg-dg-white pt-4
							transition-bgColor dark:bg-dgdark-white"
					>
						<nav
							className="relative z-10 mx-auto flex w-pc items-center justify-between"
						>
							<h1
								className="flex items-center space-x-2"
							>
								<Link
									className="relative flex"
									href={pathsData.home ?? ``}
								>
									<Image
										alt="Qoo10"
										height={70}
										onError={imageValidate.onError}
										src="/images/pc/common/logo.png"
										width={144}
										priority
									/>
								</Link>

								{
									type === `logoOnly` && (
										<span
											className="text-xs"
										>
											{t(`Qoo10 - Smart Shopping Paradise !`)}
										</span>
									)
								}
							</h1>

							{
								type !== `logoOnly` ?
									(
										<>
											<HeaderInfoView
												logoutFunc={logoutFunc}
											/>

											<HeaderSearch />
										</>
									) :
									(
										<Link
											className="relative mt-5 flex"
											href={pathsData.ebay}
											target="_blank"
										>
											<Image
												alt="In cooperation with ebay"
												height={31}
												onError={imageValidate.onError}
												src="/images/pc/common/logo-icwebay.gif"
												width={72}
											/>
										</Link>
									)
							}
						</nav>

						{
							type !== `logoOnly` && (
								<>
									<HeaderMenu
										title={title}
									/>

									{
										type !== `headerOnly` && type !== `subHeaderOnly` && (
											<>
												<article
													className="absolute left-1/2 top-full -ml-134.5 hidden
														w-32 -translate-x-1/2 justify-end"
												>
													<Link
														className="relative block w-max flex-none"
														href={pathsData.ebay}
														target="_blank"
													>
														<Image
															alt="Left Banner"
															onError={imageValidate.onError}
															sizes="128px"
															src="/images/pc/common/logo-icwebay.gif"
															fill
														/>
													</Link>

													<Button
														className="absolute right-2 top-2 z-10 bg-dg-black/80 p-1
															text-dg-white dark:bg-dgdark-black/80
															dark:text-dgdark-white"
													>
														<XMarkIcon
															className="w-4"
														/>
													</Button>
												</article>

												<HeaderSideBar
													title={title}
												/>
											</>
										)
									}
								</>
							)
						}

						{
							loginMenuVisible === true && (
								<HeaderLoginMenuView
									loginMenuSelected={loginMenuSelected}
									loginSeller={loginSeller}
								/>
							)
						}
					</header>
				)
			}
		</>
	)
}

export default HeaderView
