import {
	apiFunc
} from '@dg/common/lib/common'

export interface AffiliateApiProps {
	code: number;
	message?: string;
	result?: string;
}

/* Affiliate */
const affiliateApi = async (formData: URLSearchParams) => {
	// eslint-disable-next-line max-len
	const response = await apiFunc(`/api/member/affiliate`, false, `post`, Object.fromEntries(formData))

	return response.data as AffiliateApiProps
}

export interface AffiliateContractCdApiProps {
	code: number;
	message?: string;
	result: string;
}

/* Affiliate Contract Code */
const affiliateContractCdApi = async () => {
	// eslint-disable-next-line @stylistic/max-len
	const response = await apiFunc(`/api/legacy/member/affiliate-contract-cd`)

	return response.data as AffiliateContractCdApiProps
}

export interface CaptchaApiProps {
	code: number;
	message?: string;
	result: {
		q_captcha_image_url: string;
		q_captcha_req_no: string;
		visible: boolean;
	};
}

/* Captcha Image */
const captchaApi = async (loginId = ``, alwaysUsed = false) => {
	// eslint-disable-next-line @stylistic/max-len
	const response = await apiFunc(`/api/legacy/qcaptcha${alwaysUsed === false ? `/FRONT` : ``}${loginId !== `` ? `/${loginId}` : ``}`)

	return response.data as CaptchaApiProps
}

export interface DynamicAdLogApiProps {
	code: number;
	message?: string;
	result: number;
}

/* Dynamic Ad Log */
// eslint-disable-next-line @stylistic/max-len
const dynamicAdLogApi = async (jsonData: {
	ad_code: string;
	device: `DESKTOP-WEB` | `MOBILE-IOS` | `MOBILE-AOS`;
	landing_url: string;
	referrer: string;
	target_url: string;
}) => {
	// eslint-disable-next-line @stylistic/max-len
	const response = await apiFunc(`/api/legacy/common/dynamic-ad/log`, false, `post`, jsonData)

	return response.data as DynamicAdLogApiProps
}

export interface HealthCheckApiProps {
	status: number;
}

/* Health Check */
const healthCheckApi = async () => {
	const response = await apiFunc(`/healthcheck`)

	return response.data as HealthCheckApiProps
}

export interface HeaderApiProps {
	code: number;
	message?: string;
	result: {
		buyer_gr: `B1` | `G1` | `N1` | `R1` | `V1` | `V2`; // B1: 블랙리스트, G1: Gray, N1: 일반, R1: Silver, V1: Gold, V2: Vip
		cart_cnt: number;
		cust_nm: string;
		member_kind: `M` | `N`;
		new_msg_cnt: number;
	};
}

/* Header 정보 */
const headerApi = async () => {
	const response = await apiFunc(`/api/legacy/my/pc/header`)

	return response.data as HeaderApiProps
}

export interface InfoApiProps {
	code: number;
	message?: string;
	result: string;
}

/* 임시 회원 정보 확인용(local test시 사용) */
const infoApi = async () => {
	const response = await apiFunc(`/api/me`)

	return response.data as InfoApiProps
}

interface LogoutApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* 로그아웃 */
const logoutApi = async () => {
	const response = await apiFunc(`/api/logout`, false, `post`)

	return response.data as LogoutApiProps
}

export interface SearchRelatedApiProps {
	code: number;
	message?: string;
	result: {
		auto_change: string;
		result_list: string[];
	};
}

/* 키워드 연관 검색어 */
const searchRelatedApi = async (keyword: string) => {
	const response = await apiFunc(`/api/legacy/search/related/${keyword}`)

	return response.data as SearchRelatedApiProps
}

export interface SnsShortUrlApiProps {
	code: number;
	message?: string;
	result: string;
}

/* SNS 단축 URL */
// eslint-disable-next-line @stylistic/max-len
const snsShortUrlApi = async (title: string, shareUrl: string, picture: string, sellPrice: number, snsCd: string, connectYN: string, affiliateContractCd: string, message: string) => {
	// eslint-disable-next-line @stylistic/max-len
	const response = await apiFunc(`/api/legacy/member/sns/short-url?title=${title}&share_url=${shareUrl}&picture=${picture}&sell_price=${sellPrice}&sns_cd=${snsCd}&connect_yn=${connectYN}&affiliate_contract_cd=${affiliateContractCd}&message=${message}`)

	return response.data as SnsShortUrlApiProps
}

export {
	affiliateApi,
	affiliateContractCdApi,
	captchaApi,
	dynamicAdLogApi,
	headerApi,
	healthCheckApi,
	infoApi,
	logoutApi,
	searchRelatedApi,
	snsShortUrlApi
}
