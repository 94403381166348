import Link from '@dg/common/components/Link'
import {
	CategoryMenusApiProps
} from '@dg/common/lib/pc/api'
import {
	ChevronRightIcon
} from '@heroicons/react/24/outline'
import {
	FocusEvent, MouseEvent
} from 'react'

interface HeaderMenuCategoryUlViewProps {
	active1: string;
	active2: string;
	beforeCategory?: string;
	category: boolean;
	categoryUlFunc: (
		event: FocusEvent<HTMLAnchorElement | HTMLButtonElement | HTMLLIElement, Element> | MouseEvent) => void;
	depth: number;
	index?: number;
	list: CategoryMenusApiProps[`result`];
}

const HeaderMenuCategoryUlView = ({
	list, depth, beforeCategory, active1, active2, category, index, categoryUlFunc
}: HeaderMenuCategoryUlViewProps) => (
	<ul
		className={
			`
				absolute box-content border-x border-b border-solid border-[#eeeeee] bg-dg-white
				dark:bg-dgdark-white
				${depth === 0 ? `left-0 top-full w-full shadow-lg` : `left-full top-0 h-full w-57.5`}
				${
					index === undefined && category === false ?
						`hidden` :
						``
				}
				${
					depth === 1 && `${depth - 1}-${index ?? ``}` !== active1 ?
						`hidden` :
						``
				}
				${
					depth === 2 && `${depth - 1}-${index ?? ``}` !== active2 ?
						`hidden` :
						``
				}
			`
		}
	>
		{
			list.map((listItem, listIndex) => {
				const link =
					listItem.group_cd !== undefined ?
						`${process.env.legacyPcUrl}/gmkt.inc/Category/Group.aspx?g=${listItem.group_cd}` :
						// eslint-disable-next-line @stylistic/max-len
						`${process.env.legacyPcUrl}/cat${beforeCategory !== undefined && beforeCategory !== `` ? `${beforeCategory}` : ``}/${listItem.category_cd}`

				const categoryLinkChk1 = listItem.sub_category_list !== undefined && listItem.sub_category_list !== null

				const categoryLinkChk2 =
					listItem.filter_category_list !== undefined && listItem.filter_category_list !== null

				const categoryListChk1 = listItem.sub_category_list !== undefined && listItem.sub_category_list !== null

				const categoryListChk2 =
					listItem.filter_category_list !== undefined && listItem.filter_category_list !== null

				return (
					<li
						key={Object.keys(Array.from(list))[listIndex]}
						className={
							`
								${depth === 0 && listIndex > 0 ? `border-t border-solid border-[#eeeeee]` : ``}
								${depth > 0 && listIndex === 0 ? `mt-2.5` : ``}
							`
						}
					>
						<Link
							data-depth={`${depth}-${listIndex}`}
							href={link}
							onFocus={categoryUlFunc}
							onMouseOver={categoryUlFunc}
							className={
								`
									relative flex size-full items-center px-3 text-sm hover:font-bold
									hover:text-[#527fef]
									${depth === 0 ? `py-3` : `py-0.5`}
									${
										`${depth}-${listIndex}` === active1 ||
										`${depth}-${listIndex}` === active2 ?
											`active font-bold text-[#527fef]` :
											``
									}
								`
							}
						>
							<span>
								{listItem.group_nm || listItem.category_nm}
							</span>

							{
								(categoryLinkChk1 || categoryLinkChk2) && (
									<ChevronRightIcon
										className="ml-1 hidden w-3"
									/>
								)
							}
						</Link>

						{
							(categoryListChk1 || categoryListChk2) && (
								<HeaderMenuCategoryUlView
									active1={active1}
									active2={active2}
									category={category}
									categoryUlFunc={categoryUlFunc}
									depth={depth + 1}
									index={listIndex}
									list={listItem.sub_category_list ?? listItem.filter_category_list}
									beforeCategory={
										`
											${
													beforeCategory !== undefined && beforeCategory !== `` ?
														`${beforeCategory}` :
														``
											}
											${listItem.category_cd !== undefined ? `/${listItem.category_cd}` : ``}
										`.trim().replace(/\n\s+/gim, ``)
									}
								/>
							)
						}
					</li>
				)
			})
		}
	</ul>
)

export default HeaderMenuCategoryUlView
