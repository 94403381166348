import Spinner from '@dg/common/components/Spinner'

interface AsyncBoundaryErrorViewProps {
	className?: string;
}

const AsyncBoundaryErrorView = ({
	className = ``
}: AsyncBoundaryErrorViewProps) => (
	<Spinner
		className={`rotate-180 ${className}`}
	>
		<span
			className="sr-only"
		>
			Error
		</span>
	</Spinner>
)

export default AsyncBoundaryErrorView
