import {
	addCookie
} from '@dg/common/lib/common'
import {
	authData
} from '@dg/common/lib/store'
import {
	useAtom
} from 'jotai'
import {
	useCallback, useMemo
} from 'react'

const useLogin = () => {
	const [
		authInitial,
		setAuthInitial
	] = useAtom(authData(`initial`))

	const [
		,
		setAuthUserData
	] = useAtom(authData(`userData`))

	const data = useMemo(() => ({
		flag: authInitial as boolean
	}), [
		authInitial
	])

	const set = useCallback(({
		flag,
		userData
	}: {
		flag?: boolean;
		userData?: string;
	}) => {
		if (flag !== undefined) {
			setAuthInitial(flag)
		}

		if (userData !== undefined) {
			setAuthUserData(userData)
		}

		data.flag = flag ?? false

		if (data.flag === false) {
			addCookie(`jwtaccess=false`, 0)
			addCookie(`jwtlogout=true`, 1)
		} else {
			const jwtCookieName = `jwtaccess=`

			addCookie(`jwtaccess=${document.cookie?.split(jwtCookieName)[1]?.split(`;`)[0] || `true`}`, 1)
			addCookie(`jwtlogout=false`, 0)
		}
	}, [
		data,
		setAuthInitial,
		setAuthUserData
	])

	return {
		info: data.flag,
		set
	}
}

export default useLogin
