import Button from '@dg/common/components/Button'
import HeaderMenuCategoryUlView from '@dg/common/components/Pc/Header/HeaderView/HeaderMenu/HeaderMenuView/HeaderMenuCategoryUlView'
import useGetApi from '@dg/common/lib/hooks/useGetApi'
import {
	categoryMenusApi, CategoryMenusApiProps
} from '@dg/common/lib/pc/api'
import {
	Bars3Icon
} from '@heroicons/react/24/outline'
import {
	UseQueryResult
} from '@tanstack/react-query'
import {
	FocusEvent, MouseEvent
} from 'react'
import {
	useTranslation
} from 'react-i18next'

interface HeaderMenuCategoryViewProps {
	active1: string;
	active2: string;
	category: boolean;
	categoryUlFunc: (
		event: FocusEvent<HTMLAnchorElement | HTMLButtonElement | HTMLLIElement, Element> | MouseEvent) => void;
}

const HeaderMenuCategoryView = ({
	active1, active2, category, categoryUlFunc
}: HeaderMenuCategoryViewProps) => {
	const {
		t
	} = useTranslation()

	const headerPcCategoryMenuApi = useGetApi({
		api: categoryMenusApi,
		name: `headerPcCategoryMenuApi`
	}) as UseQueryResult<CategoryMenusApiProps>

	return (
		<li
			className="category relative w-43.5 flex-none"
			onBlur={categoryUlFunc}
			onMouseOut={categoryUlFunc}
		>
			<Button
				onFocus={categoryUlFunc}
				onMouseOver={categoryUlFunc}
				className="flex size-full items-center border-r border-solid border-[#3553e9] px-4 text-sm font-bold
					text-dg-white hover:bg-dg-black/[0.2] dark:text-dgdark-white dark:hover:bg-dgdark-black/[0.2]"
			>
				<Bars3Icon
					className="mr-1 h-5 flex-none"
				/>

				{t(`카테고리`)}
			</Button>

			<HeaderMenuCategoryUlView
				active1={active1}
				active2={active2}
				category={category}
				categoryUlFunc={categoryUlFunc}
				depth={0}
				list={headerPcCategoryMenuApi.data?.result ?? []}
			/>
		</li>
	)
}

export default HeaderMenuCategoryView
