import Link from '@dg/common/components/Link'
import {
	PropsWithChildren
} from 'react'

interface HeaderMenuLiViewProps {
	className?: string;
	link: string;
	selected?: boolean;
}

const HeaderMenuLiView = ({
	children, className = ``, link, selected = false
}: PropsWithChildren<HeaderMenuLiViewProps>) => (
	<li
		className={`relative flex-none text-sm text-dg-white dark:text-dgdark-white ${className}`}
	>
		<Link
			href={link}
			className={
				`
					flex h-full items-center px-4 font-bold hover:bg-dg-black/20 dark:hover:bg-dgdark-black/20
					${selected === true ? `bg-dg-black/20 dark:bg-dgdark-black/20` : ``}
				`
			}
		>
			{children}
		</Link>
	</li>
)

export default HeaderMenuLiView
