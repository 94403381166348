import {
	apiFunc
} from '@dg/common/lib/common'

/* 하단 탭바 추가 노출 정보 */
export interface BottomInfoApiProps {
	code: number;
	message?: string;
	result: {
		message_count: number;
		recent_view_goods: {
			adult_yn: string;
			ga_info: {
				brand_country: string;
				ctg: string;
				final_price: number;
				gd_no: string;
				gdlc_cd: string;
				gdmc_cd: string;
				group_cd: string;
				is_cps: string;
				position: number;
				power_rank_up: number;
				priority: string;
				product_name: string;
				promotion: string;
				special_sid: string;
				tid: string;
				video: string;
			};
			gd_nm: string;
			gd_no: string;
			href: string;
			img_content_no: number;
			img_src: string;
			move_yn: string;
			wish_list_yn: string;
		}[];
		wish_count: number;
	};
}

const bottomInfoApi = async () => {
	const response = await apiFunc(`/api/legacy/my/bottom/info`)

	return response.data as BottomInfoApiProps
}

/* 하단 탭바 위시리스트 추가 */
export interface SearchMobilePopupApiProps {
	code: number;
	message?: string;
	result: {
		banner: {
			link: string;
			src: string;
			title: string;
		};
		categories: {
			connect_url: string;
			image_url: string;
			name: string;
		}[];
		hot_keywords: {
			keyword: string;
		}[];
	};
}

const searchMobilePopupApi = async (count = 10) => {
	const response = await apiFunc(`/api/legacy/search/mobile-popup/${count}`)

	return response.data as SearchMobilePopupApiProps
}

/* 하단 탭바 위시리스트 추가 */
export interface WishApiProps {
	code: number;
	message?: string;
	result: boolean;
}

const wishApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/legacy/my/wish`, false, `post`, Object.fromEntries(formData))

	return response.data as WishApiProps
}

/* 하단 탭바 위시리스트 제거 */
export interface WishRemoveApiProps {
	code: number;
	message?: string;
	result: boolean;
}

const wishRemoveApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/legacy/my/wish/delete`, false, `post`, Object.fromEntries(formData))

	return response.data as WishRemoveApiProps
}

export {
	bottomInfoApi,
	searchMobilePopupApi,
	wishApi,
	wishRemoveApi
}
