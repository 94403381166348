interface SkeletonProps {
	className?: string;
}

const Skeleton = ({
	className = ``
}: SkeletonProps) => (
	<div
		className={`animate-pulse bg-dg-400 dark:bg-dgdark-400 ${className}`}
	/>
)

export default Skeleton
