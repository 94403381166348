import HeaderSideBarView from '@dg/common/components/Pc/Header/HeaderView/HeaderSideBar/HeaderSideBarView/HeaderSideBarView'
import {
	useCallback
} from 'react'

interface HeaderSideBarProps {
	title: string;
}

const HeaderSideBar = ({
	title
}: HeaderSideBarProps) => {
	const goTopFunc = useCallback(() => {
		window.scrollTo({
			behavior: `smooth`,
			top: 0
		})
	}, [])

	const moveDownFunc = useCallback(() => {
		const windowHalfHeight = window.innerHeight / 2

		window.scrollTo({
			behavior: `smooth`,
			top: window.scrollY + windowHalfHeight
		})
	}, [])

	const props = {
		goTopFunc,
		moveDownFunc,
		title
	}

	return (
		<HeaderSideBarView
			{...props}
		/>
	)
}

export default HeaderSideBar
