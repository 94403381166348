import {
	atom, createStore
} from 'jotai'
import {
	atomFamily
} from 'jotai/utils'
import {
	ReactNode
} from 'react'

export const store = createStore()

export const ssrAtom = atom<string | null>(null)

type CommonName = `appToken` | `deviceInfo` | `title`

interface CommonDataProps {
	appToken: string;
	deviceInfo: string;
	title: string;
}

const commonValue = atom<CommonDataProps>({
	appToken: ``,
	deviceInfo: ``,
	title: `Qoo10`
})

export const commonData = atomFamily((name: CommonName) => atom(
	(get) => get(commonValue)[name],
	(get, set, newValue) => {
		set(commonValue, {
			...get(commonValue),
			[name]: newValue
		})
	}
))

type AuthName = `initial` | `lang` | `userData`

interface AuthDataProps {
	initial: boolean;
	lang: string;
	userData: string;
}

const authValue = atom<AuthDataProps>({
	initial: false,
	lang: ``,
	userData: ``
})

export const authData = atomFamily((name: AuthName) => atom(
	(get) => get(authValue)[name],
	(get, set, newValue) => {
		set(authValue, {
			...get(authValue),
			[name]: newValue
		})
	}
))

export const commonLoadingValue = atom<boolean>(false)

export interface LayerpopupValueProps {
	callbackFunc?: () => void;
	id: string;
	open?: boolean;
	text?: ReactNode;
	title?: ReactNode;
	transition?: boolean;
}

const layerpopupDataValue = atom<Record<string, LayerpopupValueProps>>({})

export const layerpopupData = atom(
	(get) => get(layerpopupDataValue),
	(get, set, newValue: Record<string, LayerpopupValueProps>) => {
		set(layerpopupDataValue, {
			...get(layerpopupDataValue),
			...newValue
		})
	}
)

export interface ToastValueProps {
	id: string;
	open?: boolean;
	text?: ReactNode;
	transition?: boolean;
}

const toastDataValue = atom<Record<string, ToastValueProps>>({})

export const toastData = atom(
	(get) => get(toastDataValue),
	(get, set, newValue: Record<string, ToastValueProps>) => {
		set(toastDataValue, {
			...get(toastDataValue),
			...newValue
		})
	}
)

export interface TooltipValueProps {
	id: string;
	open?: boolean;
	target?: HTMLElement;
	text?: ReactNode;
	title?: ReactNode;
	transition?: boolean;
}

const tooltipDataValue = atom<Record<string, TooltipValueProps>>({})

export const tooltipData = atom(
	(get) => get(tooltipDataValue),
	(get, set, newValue: Record<string, TooltipValueProps>) => {
		set(tooltipDataValue, {
			...get(tooltipDataValue),
			...newValue
		})
	}
)
