import Button from '@dg/common/components/Button'
import Link from '@dg/common/components/Link'
import FooterTabView from '@dg/common/components/Pc/Footer/FooterView/FooterTabView'
import {
	addCookie, device
} from '@dg/common/lib/common'
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import pathsMobileData from '@dg/common/public/json/mobile/paths'
import pathsData from '@dg/common/public/json/pc/paths'
import Image from 'next/image'
import {
	useCallback, useEffect, useState
} from 'react'
import {
	useTranslation
} from 'react-i18next'

interface FooterViewProps {
	type: `empty` | `sub`;
}

const FooterView = ({
	type
}: FooterViewProps) => {
	const {
		t
	} = useTranslation()

	const [
		pageData,
		setPageData
	] = useState({
		fixedPcChk: false
	})

	useEffect(() => {
		const dgCookieName = `FixPcVersion=`

		if (document.cookie.match(dgCookieName) !== null && pageData.fixedPcChk === false) {
			setPageData({
				fixedPcChk: true
			})
		}
	}, [
		pageData.fixedPcChk
	])

	const imageValidate = useImageValidate()

	const FixPcVersionElement = useCallback(() => {
		const className =
			`
				flex h-18 items-center justify-center rounded border-2 border-solid border-dg-400
				bg-gradient-to-b from-dg-300 to-dg-400 text-3xl font-bold
			`

		return (
			<div
				className="flex w-full flex-col space-y-2.5 p-2.5"
			>
				<Link
					className={className}
					href={device().browser.app !== undefined && device().browser.android === undefined ? pathsMobileData.iosApp : pathsMobileData.androidApp}
				>
					{t(`쇼핑 앱`)}
				</Link>

				<Button
					className={className}
					onClick={
						() => {
							addCookie(`FixPcVersion=Y`, 0)

							setTimeout(() => {
								window.location.href = pathsMobileData.home
							}, 500)
						}
					}
				>
					{t(`모바일 웹에서 보기`)}
				</Button>
			</div>
		)
	}, [
		t
	])

	return (
		type !== `empty` ?
			(
				<footer
					className="relative z-100 w-full min-w-pc bg-dg-white transition-bgColor dark:bg-dgdark-white"
					id="wrap-footer"
				>
					<FooterTabView />

					<div
						className="relative mx-auto w-pc px-4 pb-16 pt-3 text-xs leading-5"
					>
						Copyright &copy;
						{new Date().getFullYear()}

						{` `}

						<strong>
							イーベイ
						</strong>

						{` `}
						All Rights Reserved.

						<br />
						An eBay company

						{` `}

						<span
							className="inline-flex align-middle"
						>
							<Image
								alt="ebay Logo"
								height={16}
								onError={imageValidate.onError}
								src="/images/pc/common/logo-footer-ebay.png"
								width={38}
							/>
						</span>

						<Link
							className="absolute right-0 top-3"
							href={pathsData.security}
							target="_blank"
						>
							<Image
								alt="PCI DSS Logo"
								height={65}
								onError={imageValidate.onError}
								src="/images/pc/common/logo-pcidss.png"
								width={80}
								priority
							/>
						</Link>
					</div>

					{pageData.fixedPcChk === true && FixPcVersionElement()}
				</footer>
			) :
			(
				<>
					<span
						className="sr-only"
					>
						Footer
					</span>

					{pageData.fixedPcChk === true && FixPcVersionElement()}
				</>
			)
	)
}

export default FooterView
