import Button from '@dg/common/components/Button'
import HeaderRightView from '@dg/common/components/Mobile/Header/HeaderView/HeaderRightView'
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import IcBack from '@dg/common/public/icons/mobile/common/ic-back.svg'
import imageData from '@dg/common/public/json/imageData'
import {
	XMarkIcon
} from '@heroicons/react/24/outline'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import {
	useTranslation
} from 'react-i18next'

const HeaderRightSearchLayer = dynamic(() => import(`@dg/common/components/Mobile/Header/HeaderView/HeaderRightSearchLayer`))

interface HeaderViewProps {
	appBannerCloseFunc: () => void;
	appBannerLinkFunc: () => void;
	bannerVisible: boolean;
	historyBackChk: () => void;
	title: string;
	type:
		`empty` | `headerClose` | `headerCloseAppBanner` | `headerOnly` | `headerOnlyAppBanner` | `main` |
		`sub` | `subAppBanner`;
}

const HeaderView = ({
	title, type, bannerVisible, appBannerLinkFunc, appBannerCloseFunc, historyBackChk
}: HeaderViewProps) => {
	const {
		t
	} = useTranslation()

	const imageValidate = useImageValidate()

	const typeText = type.replace(/AppBanner/gim, ``)

	let adminChk = false

	const dgCookieName = `AdminLogin=`

	if (document.cookie.match(dgCookieName) !== null) {
		adminChk = document.cookie.split(dgCookieName)[1].split(`;`)[0] === `Y`
	}

	const typeNotHeaderCloseNotHeaderOnlyChk = typeText !== `headerClose` && typeText !== `headerOnly`

	return (
		<>
			{
				adminChk === true && (
					<strong
						className="sticky left-0 top-0 z-120 block w-full bg-[#ffff00] p-2 text-center text-xs"
						id="admin-banner"
					>
						{t(`현재 Admin을 통해 귀하의 계정에 로그인되어 있습니다. 고객 정보를 변경하거나 문자를 입력하지 않도록 주의하십시오.`)}
					</strong>
				)
			}

			{
				typeText !== `empty` && (
					<>
						{
							bannerVisible === true && (
								<div
									className="relative"
									id="app-banner"
								>
									<Button
										className="relative flex w-full justify-between bg-[#f5f4f7]"
										onClick={appBannerLinkFunc}
									>
										<Image
											alt="アプリダウンロードで1000円割引クーポンGET!"
											className="object-contain"
											height={56}
											onError={imageValidate.onError}
											placeholder={imageData.emptyNullImage}
											sizes="235px"
											src="/images/mobile/common/banner-appdownload1.png"
											width={235}
											priority
										/>

										<Image
											alt="ダウンロード"
											className="object-contain"
											height={56}
											onError={imageValidate.onError}
											placeholder={imageData.emptyNullImage}
											sizes="140px"
											src="/images/mobile/common/banner-appdownload2.png"
											width={140}
											priority
										/>
									</Button>

									<Button
										onClick={appBannerCloseFunc}
										className="absolute right-0 top-0 flex h-full w-10 items-center
											justify-center"
									>
										<XMarkIcon
											className="w-5 stroke-1 text-dg-800 dark:text-dgdark-800"
										/>

										<span
											className="sr-only"
										>
											Close
										</span>
									</Button>
								</div>
							)
						}

						<header
							id="wrap-header"
							className="sticky left-0 top-0 z-120 w-full border-b border-solid border-dg-400 bg-dg-white
								transition-bgColor dark:border-dgdark-400 dark:bg-dgdark-white"
						>
							<nav
								className="flex h-12.5 items-center px-4"
							>
								{
									typeText !== `headerClose` && (
										<Button
											className="w-7 flex-none rounded-full p-0.5"
											onClick={historyBackChk}
										>
											<IcBack />

											<span
												className="sr-only"
											>
												Back Button
											</span>
										</Button>
									)
								}

								<h1
									className="flex-auto truncate whitespace-nowrap px-1 text-lg transition-colors"
								>
									{title}
								</h1>

								{typeNotHeaderCloseNotHeaderOnlyChk === true && <HeaderRightView />}

								{
									typeText === `headerClose` && (
										<Button
											className="w-7 rounded-full p-0.5"
											onClick={historyBackChk}
										>
											<XMarkIcon />

											<span
												className="sr-only"
											>
												Close Button
											</span>
										</Button>
									)
								}
							</nav>
						</header>

						{typeNotHeaderCloseNotHeaderOnlyChk === true && <HeaderRightSearchLayer />}
					</>
				)
			}
		</>
	)
}

export default HeaderView
