interface ImageDataProps {
	emptyImage: `data:image/${string}`;
	emptyNullImage: `data:image/${string}`;
}

const imageData: ImageDataProps = {
	// eslint-disable-next-line @stylistic/max-len
	emptyImage: `data:image/svg+xml,%3Csvg width='165' height='165' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='165' height='165' fill='%23F0F0F0'/%3E%3Cpath d='M101 66.8129L99.1871 65L65 99.1871L66.8129 101L69.3843 98.4286H95.8571C97.2714 98.4286 98.4286 97.2714 98.4286 95.8571V69.3843L101 66.8129ZM95.8571 95.8571H71.9557L81.3286 86.4843L83.7457 88.9014C84.7486 89.9043 86.3814 89.9043 87.3843 88.9014L89.4286 86.8571L95.8571 93.2857V95.8571ZM95.8571 89.6471L91.2414 85.0314C90.2386 84.0286 88.6057 84.0286 87.6029 85.0314L85.5586 87.0757L83.1414 84.6586L95.8443 71.9557V89.6343L95.8571 89.6471Z' fill='%23C1C4CC'/%3E%3Cpath d='M70.1422 90.7141V88.1427L76.5708 81.7141L77.7022 82.8456L79.5279 81.0199L78.3965 79.8884C77.3937 78.8856 75.7608 78.8856 74.7579 79.8884L70.1422 84.5041V70.1427H90.7137V67.5713H70.1422C68.7279 67.5713 67.5708 68.7284 67.5708 70.1427V90.7141H70.1422Z' fill='%23C1C4CC'/%3E%3C/svg%3E%0A`,
	emptyNullImage: `data:image/svg+xml,%3Csvg width='165' height='165' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='165' height='165' fill='none'/%3E%3C/svg%3E`
}

export default imageData
