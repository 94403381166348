import AsyncBoundary from '@dg/common/components/AsyncBoundary'
import {
	Layout
} from '@dg/common/components/Layout'
import {
	device
} from '@dg/common/lib/common'
import {
	gaDimensionFunc
} from '@dg/common/lib/qoo10Common'
import '@dg/common/styles/_common/_common_sprite.css'
import '@dg/common/styles/destyle.css'
import '@dg/common/styles/globals.css'
import i18n from 'i18next'
import Providers from 'member/components/Providers'
import 'member/styles/_common/_common_sprite.css'
import 'member/styles/mobile/mobile_sprite.css'
import 'member/styles/pc/pc_sprite.css'
import {
	AppProps
} from 'next/app'
import Head from 'next/head'
import {
	useRouter
} from 'next/router'
import Script from 'next/script'
import {
	useCallback, useEffect, useMemo
} from 'react'
import {
	initReactI18next
} from 'react-i18next'

i18n.use(initReactI18next).init({
	fallbackLng: `ja`,
	interpolation: {
		escapeValue: false
	},
	lng: `ja`,
	resources: {}
})

// i18n.changeLanguage(`en`)

const AppPage = ({
	Component, pageProps
}: AppProps) => {
	const router = useRouter()

	const data = useMemo(() => ({
		loaded: false
	}), [])

	const handleRouteChange = useCallback((url: string) => {
		const memberUrl = process.env.memberUrl !== `` ? process.env.memberUrl : window.location.href.split(`/${device().browser.mobile !== undefined ? `mobile` : `pc`}/`)[0]

		gaDimensionFunc({
			url: url.startsWith(`https`) === false ? `${memberUrl}${url}` : url
		})
	}, [])

	useEffect(() => {
		if (data.loaded === false) {
			data.loaded = true

			handleRouteChange(window.location.href)
		}

		router.events.on(`routeChangeComplete`, handleRouteChange)

		// router.events.on(`hashChangeComplete`, handleRouteChange)

		const cleanup = () => {
			router.events.off(`routeChangeComplete`, handleRouteChange)

			// router.events.off(`hashChangeComplete`, handleRouteChange)
		}

		return cleanup
	}, [
		data,
		handleRouteChange,
		router
	])

	return (
		<>
			{/* App에서 사용하는 함수/값 */}
			<Script
				id="appScript"
			>
				{
					`
						window.MobileUtil = {
							// App CartCnt
							setCartCntFromApp: (count = 0) => {
								localStorage.setItem('appCartCnt', count)
							},
							// App QpostCnt
							setQpostCntFromApp: (count = 0) => {
								localStorage.setItem('appQpostCnt', count)
							},
						}

						// AppData to localStorage
						window.setAppDataToLocalStorage = (key, value) => {
							localStorage.setItem(key, value)
						}

						// GA
						const urlData = new URL(window.location.href)
						let _page_screen_name = "moweb"

						Array.from(urlData.pathname.replace(/\\/mobile\\/|\\/pc\\//gim, "").split("/")).map((item) => {
							_page_screen_name += ">"
							_page_screen_name += item

							return false
						})

						window.GetGAPageScreenName = () => _page_screen_name
					`
				}
			</Script>

			<Providers>
				<AsyncBoundary>
					<Layout>
						<Head>
							<meta
								// eslint-disable-next-line @stylistic/max-len
								content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no"
								name="viewport"
							/>

							<meta
								content="Qoo10 Member"
								property="og:title"
							/>

							<meta
								content="https://member.qoo10.jp/images/common/ico/favicon.png"
								property="og:image"
							/>

							<meta
								// eslint-disable-next-line @stylistic/max-len
								content="Qoo10（キューテン）はeBay Japanのネット通販サイト。ファッションからコスメ、家電、食品、生活雑貨まで何でもクーポンで割引！ポイント還元いつも最安値でお届けします。"
								property="og:description"
							/>

							<meta
								content="website"
								property="og:type"
							/>

							<meta
								content={`${process.env.memberUrl}${router.asPath}`}
								property="og:url"
							/>

							<meta
								content="Qoo10"
								property="og:site_name"
							/>
						</Head>

						<Component
							{...pageProps}
						/>
					</Layout>
				</AsyncBoundary>
			</Providers>
		</>
	)
}

export default AppPage
