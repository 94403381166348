import Layerpopup from '@dg/common/components/Layerpopup'
import Link from '@dg/common/components/Link'
import HeaderInfoLiView from '@dg/common/components/Pc/Header/HeaderView/HeaderInfoLiView'
import {
	headerApi, HeaderApiProps
} from '@dg/common/lib/api'
import useGetApi from '@dg/common/lib/hooks/useGetApi'
import useLayerpopup from '@dg/common/lib/hooks/useLayerpopup'
import useLogin from '@dg/common/lib/hooks/useLogin'
import gradeData from '@dg/common/public/json/grade'
import pathsData from '@dg/common/public/json/pc/paths'
import {
	UseQueryResult
} from '@tanstack/react-query'
import {
	useTranslation
} from 'react-i18next'

interface HeaderInfoViewProps {
	logoutFunc: () => void;
}

const HeaderInfoView = ({
	logoutFunc
}: HeaderInfoViewProps) => {
	const layerpopup = useLayerpopup()
	const login = useLogin()

	const {
		t
	} = useTranslation()

	const headerDataApi = useGetApi({
		api: headerApi,
		name: `headerDataApi`
	}) as UseQueryResult<HeaderApiProps>

	const memberGr = headerDataApi.data?.result.member_kind === `M` ? headerDataApi.data?.result.buyer_gr : `GUEST`
	const memberGroup = gradeData.customerGrade[memberGr]

	return (
		<>
			<ul
				className="absolute -top-3 right-0 flex items-center text-xs"
			>
				{
					login.info === true &&
					headerDataApi.data?.result.cust_nm !== undefined &&
					headerDataApi.data?.result.cust_nm !== null && (
						<li
							className="flex items-center space-x-1.5 pr-1"
						>
							{
								memberGr !== `G1` &&
								memberGr !== `B1` &&
								memberGr !== `GUEST` &&
								(
									<Link
										href={`${pathsData.qchance}#reward`}
										className={
											`
												flex justify-center rounded border border-solid
												${memberGroup?.colorFill || ``}
											`
										}
										px
									>
										{memberGroup?.name}
									</Link>
								)
							}

							<Link
								className={`pr-1 ${memberGroup?.colorText}`}
								href={pathsData.myMain}
							>
								{headerDataApi.data?.result.cust_nm.slice(0, 15)}

								{headerDataApi.data?.result.cust_nm.length > 15 ? `...` : ``}
							</Link>

							{t(`님`)}
						</li>
					)
				}

				<HeaderInfoLiView
					link={login.info !== true ? pathsData.login : undefined}
					onClick={
						() => {
							if (login.info === true) {
								layerpopup.open({
									callbackFunc: () => logoutFunc(),
									id: `layerpopup-logoutinfo`
								})
							}
						}
					}
				>
					{
						login.info === true ?
							t(`로그아웃`) :
							t(`로그인`)
					}
				</HeaderInfoLiView>

				<HeaderInfoLiView
					link={pathsData.myMain}
				>
					{t(`My Qoo10`)}
				</HeaderInfoLiView>

				<HeaderInfoLiView
					link={pathsData.cart}
				>
					{t(`장바구니`)}

					{
						headerDataApi.data?.result !== undefined && headerDataApi.data?.result.cart_cnt > 0 &&
						`(${headerDataApi.data?.result.cart_cnt})`
					}
				</HeaderInfoLiView>

				<HeaderInfoLiView
					link={`window_${pathsData.qpost}_815_786`}
				>
					Qpost
				</HeaderInfoLiView>

				<HeaderInfoLiView
					link={pathsData.qsm}
					target="blank"
				>
					QSM
				</HeaderInfoLiView>

				<HeaderInfoLiView
					link={pathsData.help}
				>
					{t(`도움말`)}
				</HeaderInfoLiView>

				<HeaderInfoLiView
					link={pathsData.openStore}
				>
					{t(`Qoo10에 출점`)}
				</HeaderInfoLiView>

				<HeaderInfoLiView
					link={pathsData.university}
					last
				>
					{t(`Qoo10대학`)}
				</HeaderInfoLiView>
			</ul>

			<Layerpopup
				id="layerpopup-logoutinfo"
				defaultFooter
			>
				{t(`로그아웃했습니다. Qoo10을 이용해 주셔서 감사합니다.`)}
			</Layerpopup>
		</>
	)
}

export default HeaderInfoView
