import {
	apiFunc
} from '@dg/common/lib/common'

export interface liveApiProps {
	sort: `NEW` | `VIEWS` | `PURCHASES`;
	vodStatus: `WAIT` | `LIVE` | `VOD` | `VOD_TRANS` | `ERROR`;
}

export interface EventApplyEventApiProps {
	code: number;
	message?: string;
	result: {
		result_code: number;
		result_msg: string;
	};
}

/* 쿠폰 발급 */
const eventApplyEventApi = async (eid: string) => {
	const response = await apiFunc(`/api/legacy/event/apply-event?eid=${eid}`, false, `post`)

	return response.data as EventApplyEventApiProps
}

export interface FilterApiProps {
	code: number;
	message?: string;
	result: {
		categories: {
			category_code: number;
			category_name: string;
		}[];
		channels: {
			channel_no: number;
			title: string;
		}[];
		sorts: {
			code: liveApiProps[`sort`];
			title: string;
		}[];
	};
}

/* 필터 */
const filterApi = async () => {
	const response = await apiFunc(`/api-live/filter`)

	return response.data as FilterApiProps
}

export interface LiveShoppingBannersApiProps {
	code: number;
	message?: string;
	result: {
		link: string;
		src: string;
		title: string;
	}[];
}

/* LIVESHOPPING BANNER */
const liveShoppingBannersApi = async () => {
	const response = await apiFunc(`/api/legacy/liveshopping/banners`)

	return response.data as LiveShoppingBannersApiProps
}

export interface LivesAlarmsApiProps {
	code: number;
	message?: string;
	result: {
		lives: {
			alarm_cnt: number;
			channel: {
				connect_url: string;
				image_url: string;
				title: string;
			};
			channel_no: number;
			coupon_labels?: {
				bg_color: string;
				color: string;
				short_title: string;
				title: string;
			}[];
			grip_id: string;
			has_live_benefit: boolean;
			image_url: string;
			item: {
				discount_rate: string;
				gd_nm: string;
				gd_no: string;
				image_url: string;
				move: boolean;
				price: number;
			};
			live_no: number;
			playback_url: string;
			start_dt: string;
			status: liveApiProps[`vodStatus`];
			title: string;
			view_cnt: number;
		}[];
		total_count: number;
	};
}

/* LIVE 나의 알람 내역 */
const livesAlarmsApi = async (startDate: string, endDate: string) => {
	const response = await apiFunc(`/api-live/lives/alarms?startDt=${startDate}&endDt=${endDate}`)

	return response.data as LivesAlarmsApiProps
}

export interface LivesDetailApiProps {
	code: number;
	message?: string;
	result: {
		alarm_cnt: number;
		benefit: string;
		channel: {
			connect_url: string;
			image_url: string;
			title: string;
		};
		channel_no: number;
		coupons?: {
			discount_amount: number;
			discount_rate: number;
			discount_type: `AMOUNT` | `RATE`;
			encrypt_eid: string;
			title: string;
		}[];
		grip_id: string;
		image_url: string;
		items: {
			alt_price: string;
			discount_rate: string;
			gd_nm: string;
			gd_no: string;
			image_url: string;
			move: boolean;
			price: number;
		}[];
		live_no: number;
		notice1: string;
		notice2: string;
		notice3: string;
		playback_url: string;
		preview_image_url: string;
		set_alarm: boolean;
		start_dt: string;
		status: liveApiProps[`vodStatus`];
		title: string;
		view_cnt: number;
	};
}

/* LIVE 상세 페이지 */
const livesDetailApi = async (liveNo: string) => {
	const response = await apiFunc(`/api-live/lives/${liveNo}`)

	return response.data as LivesDetailApiProps
}

export interface LivesOnAirApiProps {
	code: number;
	message?: string;
	result: {
		alarm_cnt: number;
		channel_no: number;
		coupon_labels?: {
			bg_color: string;
			color: string;
			short_title: string;
			title: string;
		}[];
		grip_id: string;
		has_live_benefit: boolean;
		image_url: string;
		item: {
			gd_nm: string;
			gd_no: string;
			image_url: string;
			move: boolean;
		};
		live_no: number;
		playback_url: string;
		start_dt: string;
		status: liveApiProps[`vodStatus`];
		title: string;
		view_cnt: number;
	}[];
}

/* LIVE 방송 정보 */
const livesOnAirApi = async () => {
	const response = await apiFunc(`/api-live/lives/on-air`)

	return response.data as LivesOnAirApiProps
}

export interface LivesRankingApiProps {
	code: number;
	message?: string;
	result: {
		alarm_cnt: number;
		channel: {
			connect_url: string;
			image_url: string;
			title: string;
		};
		channel_no: number;
		coupon_labels?: {
			bg_color: string;
			color: string;
			short_title: string;
			title: string;
		}[];
		grip_id: string;
		has_live_benefit: boolean;
		image_url: string;
		item: {
			discount_rate: string;
			gd_nm: string;
			gd_no: string;
			image_url: string;
			move: boolean;
			price: number;
		};
		live_no: number;
		playback_url: string;
		start_dt: string;
		status: liveApiProps[`vodStatus`];
		title: string;
		view_cnt: number;
	}[];
}

/* LIVE 랭킹 정보 */
const livesRankingApi = async (sort: liveApiProps[`sort`]) => {
	const response = await apiFunc(`/api-live/lives/ranking?sort=${sort}`)

	return response.data as LivesRankingApiProps
}

export interface LivesScheduleApiProps {
	code: number;
	message?: string;
	result: {
		categories: {
			category_code: number;
			category_name: string;
		}[];
		lives: {
			alarm_cnt: number;
			channel: {
				connect_url: string;
				image_url: string;
				title: string;
			};
			channel_no: number;
			coupon_labels?: {
				bg_color: string;
				color: string;
				short_title: string;
				title: string;
			}[];
			grip_id: string;
			has_live_benefit: boolean;
			image_url: string;
			item: {
				discount_rate: string;
				gd_nm: string;
				gd_no: string;
				image_url: string;
				move: boolean;
				price: number;
			}[];
			live_no: number;
			playback_url: string;
			start_dt: string;
			status: liveApiProps[`vodStatus`];
			title: string;
			view_cnt: number;
		}[];
	};
}

/* 일정 정보 */
const livesScheduleApi = async (
	startDate: string,
	endDate: string,
	includeCategory: `Y` | `N`,
	categoryCode?: number | null
) => {
	// eslint-disable-next-line @stylistic/max-len
	const response = await apiFunc(`/api-live/lives/schedule?startDt=${startDate}&endDt=${endDate}&includeCategory=${includeCategory}${categoryCode !== undefined && categoryCode !== null ? `&categoryCode=${categoryCode.toString()}` : ``}`)

	return response.data as LivesScheduleApiProps
}

export interface LivesScheduleCountPerDayApiProps {
	code: number;
	message?: string;
	result: {
		count_per_day: number;
		day: string;
	}[];
}

/* 날짜별 방송/알림 여부 체크 */
const livesScheduleCountPerDayApi = async (type: `default` | `alarm` | `list`, startDate: string, endDate: string) => {
	// eslint-disable-next-line @stylistic/max-len
	const response = await apiFunc(`/api-live/lives/${type !== `alarm` ? `schedule` : `alarm`}/count-per-day?startDt=${startDate}&endDt=${endDate} 23:59:59`)

	return response.data as LivesScheduleCountPerDayApiProps
}

export interface LivesSetAlarmApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* 알람 설정 */
const livesSetAlarmApi = async (liveNo: number, flag?: boolean) => {
	const response =
		await apiFunc(`/api-live/lives/${liveNo}/alarm${flag !== undefined ? `?flag=${flag}` : ``}`, false, `post`)

	return response.data as LivesSetAlarmApiProps
}

export interface livesVodViewCntApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* LIVE 상세 페이지 */
const livesVodViewCntApi = async (liveNo: string) => {
	const response = await apiFunc(`/api-live/lives/${liveNo}/vod-view-cnt`, false, `post`)

	return response.data as livesVodViewCntApiProps
}

export interface LivesVodsApiProps {
	code: number;
	message?: string;
	result: {
		lives: {
			alarm_cnt: number;
			channel: {
				connect_url: string;
				image_url: string;
				title: string;
			};
			channel_no: number;
			coupon_labels?: {
				bg_color: string;
				color: string;
				short_title: string;
				title: string;
			}[];
			grip_id: string;
			has_live_benefit: boolean;
			image_url: string;
			item: {
				discount_rate: string;
				gd_nm: string;
				gd_no: string;
				image_url: string;
				move: boolean;
				price: number;
			};
			live_no: number;
			playback_url: string;
			start_dt: string;
			status: liveApiProps[`vodStatus`];
			title: string;
			view_cnt: number;
		}[];
		total_count: number;
	};
}

/* VOD 정보 */
const livesVodsApi = async (
	category = 0,
	brand = 0,
	channel = 0,
	sort = `NEW`, // NEW | VIEWS | PURCHASES
	pageNo = 1,
	pageSize = 10
) => {
	// eslint-disable-next-line @stylistic/max-len
	const response = await apiFunc(`/api-live/lives/vods?category=${category}&brand=${brand}&channel=${channel}&sort=${sort}&pageNo=${pageNo}&pageSize=${pageSize}`)

	return response.data as LivesVodsApiProps
}

export interface PromotionApiProps {
	code: number;
	message?: string;
	result: {
		lives: {
			channel: {
				connect_url: string;
				image_url: string;
				title: string;
			};
			channel_no: number;
			coupon_labels?: [
				{
					bg_color: string;
					color: string;
					short_title: string;
					title: string;
				}
			];
			grip_id: string;
			has_live_benefit: boolean;
			image_url: string;
			item: {
				gd_nm: string;
				gd_no: string;
				image_url: string;
				move: boolean;
			};
			live_no: number;
			playback_url?: string;
			status: liveApiProps[`vodStatus`];
			title: string;
			view_cnt: number;
		}[];
		promotions: {
			cover_image_url: string;
			promotion_no: number;
			title: string;
		}[];
	};
}

/* 테마별 기획전 정보 */
const promotionApi = async (type: `ALL` | `MOVE` | `QOO10`) => {
	const response = await apiFunc(`/api-live/promotions?serviceType=${type}`)

	return response.data as PromotionApiProps
}

export interface PromotionNoApiProps {
	code: number;
	message?: string;
	result: {
		channel: {
			connect_url: string;
			image_url: string;
			title: string;
		};
		channel_no: number;
		coupon_labels?: [
			{
				bg_color: string;
				color: string;
				short_title: string;
				title: string;
			}
		];
		grip_id: string;
		has_live_benefit: boolean;
		image_url: string;
		item: {
			gd_nm: string;
			gd_no: string;
			image_url: string;
			move: boolean;
		};
		live_no: number;
		playback_url: string;
		status: liveApiProps[`vodStatus`];
		title: string;
		view_cnt: number;
	}[];
}

/* 테마별 기획전 개별 프로모션별 정보 */
const promotionNoApi = async (promotionNo: number) => {
	const response = await apiFunc(`/api-live/promotions/${promotionNo}`)

	return response.data as PromotionNoApiProps
}

export {
	eventApplyEventApi,
	filterApi,
	liveShoppingBannersApi,
	livesAlarmsApi,
	livesDetailApi,
	livesOnAirApi,
	livesRankingApi,
	livesScheduleApi,
	livesScheduleCountPerDayApi,
	livesSetAlarmApi,
	livesVodViewCntApi,
	livesVodsApi,
	promotionApi,
	promotionNoApi
}
