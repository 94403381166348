import FooterTabView from '@dg/common/components/Mobile/Footer/FooterView/FooterTab/FooterTabView/FooterTabView'

const FooterTab = () => {
	const parseUrl = new URL(window.location.href)

	const tabPathChk =
		Array.from(parseUrl.pathname.split(`/`))
			.filter((filterItem, filterIndex) => filterIndex < 3)
			.map((mapItem) => mapItem).join(`/`)

	const props = {
		tabPathChk
	}

	return (
		<FooterTabView
			{...props}
		/>
	)
}

export default FooterTab
