import Link from '@dg/common/components/Link'
import FooterInfoView from '@dg/common/components/Mobile/Footer/FooterView/FooterInfoView'
import FooterTab from '@dg/common/components/Mobile/Footer/FooterView/FooterTab'
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import pathsData from '@dg/common/public/json/mobile/paths'
import Image from 'next/image'

interface FooterViewProps {
	type: `empty` | `navOnly` | `sub` | `subOnly`;
}

const FooterView = ({
	type
}: FooterViewProps) => {
	const imageValidate = useImageValidate()

	return (
		type !== `empty` && (
			<>
				{type !== `subOnly` && <FooterTab />}

				{
					type?.match(/sub/) !== null && (
						<div
							id="footer-info"
							className="relative bg-[#7a7a7a] px-4 pb-5 pt-2.5 text-xs text-dg-white
								dark:text-dgdark-white"
						>
							<FooterInfoView />

							<p
								className="mt-1.5"
							>
								Copyright &copy;
								{new Date().getFullYear()}

								{` `}
								イーベイ
							</p>

							<Link
								className="absolute bottom-3 right-0 h-7.5 w-10"
								href={pathsData.security}
								target="_blank"
							>
								<Image
									alt="PCI DSS Logo"
									onError={imageValidate.onError}
									sizes="40px"
									src="/images/mobile/common/logo-pcidss.png"
									fill
								/>
							</Link>
						</div>
					)
				}
			</>
		)
	)
}

export default FooterView
