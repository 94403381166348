import {
	Component, ErrorInfo, ReactNode
} from 'react'

interface AsyncBoundaryErrorWrapViewProps {
	props: {
		children: ReactNode;
		fallback: ReactNode;
	};
	state: {
		hasError: boolean;
	};
}

class ErrorBoundaryView extends Component<AsyncBoundaryErrorWrapViewProps[`props`], AsyncBoundaryErrorWrapViewProps[`state`]> {
	constructor(props: AsyncBoundaryErrorWrapViewProps[`props`]) {
		super(props)

		this.state = {
			hasError: false
		}
	}

	static getDerivedStateFromError(error: Error) {
		return {
			error,
			hasError: true
		}
	}

	static componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error(`Uncaught error:`, error, errorInfo)
	}

	render() {
		const {
			state, props
		} = this

		if (state.hasError === true) {
			return props.fallback

			// return <h1>Sorry. there was an error.</h1>
		}

		return props.children
	}
}

export default ErrorBoundaryView
