import {
	ButtonHTMLAttributes, PropsWithChildren
} from 'react'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	bg?: boolean;
	btnType?: `darkGray` | `lightGray` | `mobileRed` | `pcBlue` | `black`;
	className?: string;
	hover?: boolean;
	pd?: boolean;
	px?: boolean;
	py?: boolean;
	rounded?: boolean;
	transition?: boolean;
}

const Button = (props: PropsWithChildren<ButtonProps>) => {
	const {
		children, className = ``, type = `button`, btnType = ``,
		rounded = false, px = false, py = false, pd = false, hover = false, bg = false, transition = true,
		...rest
	} = props

	const customClass =
		`
			disabled:cursor-not-allowed disabled:text-dg-550 dark:disabled:text-dgdark-550
			${transition === true ? `transition-colors` : ``}
			${pd === true ? `p-4` : ``}
			${py === true ? `py-4` : ``}
			${px === true ? `px-4` : ``}
			${rounded === true ? `rounded-lg` : ``}
			${bg === true ? `bg-dg-300 dark:bg-dgdark-300` : ``}
			${hover === true ? `hover:bg-dg-600/50 dark:hover:bg-dgdark-600/50` : ``}
			${
				btnType === `pcBlue` ?
					`
						bg-[#517fef] text-dg-white disabled:bg-dg-500
						disabled:text-dg-white dark:text-dgdark-white
						dark:disabled:bg-dgdark-500 dark:disabled:text-dgdark-white
					` :
					``
			}
			${
				btnType === `mobileRed` ?
					`
						bg-dg-red text-dg-white disabled:bg-dg-500
						disabled:text-dg-white dark:bg-dgdark-red dark:text-dgdark-white
						dark:disabled:bg-dgdark-500 dark:disabled:text-dgdark-white
					` :
					``
			}
			${
				btnType === `darkGray` ?
					`
						bg-[#6e7188] text-dg-white disabled:bg-dg-500
						disabled:text-dg-white dark:text-dgdark-white
						dark:disabled:bg-dgdark-500 dark:disabled:text-dgdark-white
					` :
					``
			}
			${
				btnType === `lightGray` ?
					`
						border border-solid border-dg-500 bg-dg-200 text-dg-900 disabled:bg-dg-200
						disabled:text-dg-900 dark:border-dgdark-500 dark:text-dgdark-900
						dark:disabled:bg-dgdark-200 dark:disabled:text-dgdark-900
						dark:bg-dgdark-200
					` :
					``
			}
			${
				btnType === `black` ?
					`
						border border-solid border-dg-black bg-dg-black text-dg-white disabled:bg-dg-200
						disabled:border-dg-500 disabled:text-dg-900 dark:border-dgdark-500
						dark:text-dgdark-900
						dark:disabled:bg-dgdark-200 dark:disabled:border-dgdark-500
						dark:disabled:text-dgdark-900 dark:bg-dgdark-200
					` :
					``
			}
			${className}
		`.trim().replace(/\n\s+/gim, ` `)

	return (
		<button
			{...rest}
			className={customClass}
			type={type}
		>
			{children}
		</button>
	)
}

export default Button
