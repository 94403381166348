import {
	useQuery, QueryFunction, UseQueryOptions, QueryKey
} from '@tanstack/react-query'
import {
	AxiosError
} from 'axios'

interface UseGetApiProps {
	api: QueryFunction;
	errorCallback?: (error: AxiosError) => void;
	gcTime?: number;
	name: string;
	successCallback?: (successData: never) => void;
}

const useGetApi = ({
	name, api, gcTime = 600000, successCallback, errorCallback
}: UseGetApiProps) => {
	const useGetApiQuery = useQuery({
		errorCallback,
		gcTime,
		queryFn: api,
		queryKey: [
			`${name}`
		],
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: gcTime,
		successCallback
	} as unknown as UseQueryOptions<unknown, Error, unknown, QueryKey>)

	return useGetApiQuery
}

export default useGetApi
