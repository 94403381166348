import {
	layerpopupData,
	type LayerpopupValueProps
} from '@dg/common/lib/store'
import {
	useAtom
} from 'jotai'
import {
	useCallback
} from 'react'

const useLayerpopup = () => {
	const [
		layerpopup,
		setLayerpopup
	] = useAtom(layerpopupData)

	const get = useCallback(() => layerpopup, [
		layerpopup
	])

	const set = useCallback(({
		id = ``, open = false, title = ``, text = ``, transition = true, callbackFunc
	}: LayerpopupValueProps) => {
		const setData = layerpopup

		setData[id] = {
			callbackFunc,
			id,
			open,
			text,
			title,
			transition
		}

		setLayerpopup(setData)
	}, [
		layerpopup,
		setLayerpopup
	])

	const open = useCallback(({
		id = ``, title = ``, text = ``, transition = true, callbackFunc
	}: LayerpopupValueProps) => {
		const setData = layerpopup

		if (setData[id] !== undefined) {
			setData[id] = {
				...setData[id],
				callbackFunc,
				open: true,
				text: text !== `` ? text : setData[id].text,
				title: title !== `` ? title : setData[id].title,
				transition
			}

			setLayerpopup(setData)
		}
	}, [
		layerpopup,
		setLayerpopup
	])

	const close = useCallback((id = ``, transition = true) => {
		const setData = layerpopup

		if (setData[id] !== undefined) {
			setData[id] = {
				...setData[id],
				open: false,
				transition
			}

			setLayerpopup(setData)
		}
	}, [
		layerpopup,
		setLayerpopup
	])

	return {
		close,
		get,
		open,
		set
	}
}

export default useLayerpopup
