import {
	device
} from "@dg/common/lib/common"

export const appierId = process.env.appEnv === `production` ? `644b8d83a794e4a4d54c` : `b35c9cec83e38d7587a6`

export const gtagId = `DC-10155906`

export const gtmId = `GTM-TH65ZF7`

declare const window: {
	dataLayer: Record<string, string | undefined | Record<string, string | Record<string, string>[]>>[];
	ebaygascriptAndroid: {
		GA4_DATA: (object: string) => void;
	};
}

declare const webkit: {
	messageHandlers: {
		ebaygascriptCallbackHandler: {
			postMessage: (object: string) => void;
		};
	};
}

let commonData = {}
let virCommonData = {}

/*
- 매개변수 제거 함수
- 매개변수 값이 "" / null / undefined일 시 해당 필드를 객체에서 제거합니다.
*/
export const removeEmptyElement = (removeValue: Record<string, string>) => {
	const setRemoveValue = removeValue
	let returnValue = {}

	for (const key in setRemoveValue) {
		if (setRemoveValue[key] === `` || setRemoveValue[key] === null || setRemoveValue[key] === undefined) {
			delete setRemoveValue[key]
		}
	}

	returnValue = setRemoveValue

	return returnValue
}

/*
- dataLayer 초기화 함수
- 해당 함수는 dataLayer를 초기화하여 이전 데이터의 중복 전송을 방지합니다.
*/
// eslint-disable-next-line @stylistic/max-len
export const resetDataLayer = (targetObject: Record<string, string | Record<string, string | Record<string, string>[]>>) => {
	const setGTM = {} as Record<string, string | undefined>

	for (const key in targetObject) {
		if (typeof Object.hasOwn !== `undefined`) {
			if (Object.hasOwn(targetObject, key) || Object.prototype.hasOwnProperty.call(targetObject, key)) {
				setGTM[key] = undefined
			}
		}
	}

	return window.dataLayer.push(setGTM)
}

/*
- 하이브리드 함수
- 앱사용자일 경우 데이터를 JSON 형태로 앱으로 전달합니다.
- 일반 페이지와 가상 페이지를 구분하여 데이터를 설정합니다.
*/
// eslint-disable-next-line @stylistic/max-len
export const hybrid = (object: Record<string, string | Record<string, string> | Record<string, string>[]>, isVirtual?: boolean) => {
	const GAData = isVirtual ?
		{
			...virCommonData,
			...object
		} :
		{
			...commonData,
			...object
		}

	if (device().browser.app !== undefined) {
		if (device().browser.android === true) {
			window.ebaygascriptAndroid.GA4_DATA(JSON.stringify(GAData))
		} else {
			webkit.messageHandlers.ebaygascriptCallbackHandler.postMessage(JSON.stringify(GAData))
		}
	}
}

/*
- 페이지뷰 전송 함수
- 앱과 웹 사용자를 구분하며, 화면 데이터를 전송합니다.
*/
export const sendGAPage = (object: Record<string, string>) => {
	let setObject = object

	try {
		setObject = removeEmptyElement(setObject)
		commonData = {
			...setObject
		}

		if (device().browser.app !== undefined) {
			// setObject.type = `P`
			// hybrid(setObject)
		} else {
			setObject.event = `ga_page`
			window.dataLayer.push(setObject)
		}
	} catch (e) {
		console.log(`sendGAPage 함수 ERROR`)

		if (e instanceof Error) {
			console.log(e.message)
		}
	}
}

/*
- 가상 페이지뷰 전송 함수
- 앱과 웹 사용자를 구분하며, 화면 데이터를 전송합니다.
- 해당 함수는 가상 페이지 전용 함수입니다.
*/
export const sendGAVirPage = (virObject: Record<string, string>) => {
	let setVirObject = virObject

	try {
		setVirObject = removeEmptyElement(setVirObject)
		virCommonData = {
			...setVirObject
		}

		if (device().browser.app !== undefined) {
			// setVirObject.type = `P`
			// hybrid(setVirObject, true)
		} else {
			setVirObject.event = `ga_virtual`
			window.dataLayer.push(setVirObject)
			resetDataLayer(setVirObject)
		}
	} catch (e) {
		console.log(`sendVirPage 함수 ERROR`)

		if (e instanceof Error) {
			console.log(e.message)
		}
	}
}

/*
- 이벤트 전송 함수 - 객체
- 해당 함수는 매개변수를 객체로 받아 사용합니다.
- 가상 페이지에서 사용 시 isVirtual 매개변수를 반드시 설정해야 합니다.
*/
export const sendGAEvent = (object: Record<string, string>, isVirtual?: boolean) => {
	try {
		let GAData: Record<string, string> = removeEmptyElement(object)

		if (device().browser.app !== undefined) {
			GAData.type = `E`
			hybrid(GAData, isVirtual)
		} else {
			GAData.event = `ga_event`

			if (isVirtual === true) {
				GAData = {
					...virCommonData,
					...GAData
				}
			} else {
				GAData = {
					...GAData
				}
			}

			window.dataLayer.push(GAData)
			resetDataLayer(GAData)
		}
	} catch (e) {
		console.log(`sendGAEvent 함수 ERROR`)

		if (e instanceof Error) {
			console.log(e.message)
		}
	}
}

/*
- 이벤트 전송 함수 - 속성
- 앱과 웹 사용자를 구분하며, 이벤트 데이터를 전송합니다.
- 해당 함수는 HTML attribute에서 이벤트 매개변수를 추출하여 데이터를 정의합니다.
- 가상 페이지에서 사용 시 isVirtual 매개변수를 반드시 설정해야 합니다.
*/
export const sendGAAttrEvent = (event: Event, isVirtual?: boolean) => {
	try {
		const ELE = event.currentTarget as HTMLElement
		const ATTR = ELE.getAttributeNames()

		let GAData = {} as Record<string, string>

		for (const [
			i
		] of ATTR.entries()) {
			if (ATTR[i].includes(`ep_`)) {
				GAData[ATTR[i]] = ELE.getAttribute(ATTR[i]) ?? ``
			}
		}

		GAData.event_name = ELE.getAttribute(`event_name`) ?? ``

		if (device().browser.app !== undefined) {
			GAData.type = `E`
			hybrid(GAData, isVirtual)
		} else {
			GAData.event = `ga_event`

			if (isVirtual === true) {
				GAData = {
					...virCommonData,
					...GAData
				}
			} else {
				GAData = {
					...GAData
				}
			}

			window.dataLayer.push(GAData)
			resetDataLayer(GAData)
		}
	} catch (e) {
		console.log(`sendGAAttrEvent 함수 ERROR`)

		if (e instanceof Error) {
			console.log(e.message)
		}
	}
}

/*
- 전자상거래 전송 함수
- 앱과 웹 사용자를 구분하며, 전자상거래 데이터를 전송합니다.
- 가상 페이지에서 사용 시 isVirtual 매개변수 위치에 반드시 값을 설정해야 합니다.
*/
// eslint-disable-next-line @stylistic/max-len
export const sendGAEcommerce = (eventData: Record<string, string>, transaction: Record<string, string>, items: Record<string, string>[], isVirtual?: boolean) => {
	try {
		let setEventData = eventData
		let setTransaction = transaction
		const setItems = items

		setEventData = removeEmptyElement(setEventData)
		setTransaction = removeEmptyElement(setTransaction)

		for (const [
			i
		] of items.entries()) {
			setItems[i] = removeEmptyElement(setItems[i])
		}

		if (device().browser.app !== undefined) {
			const GAData: Record<string, string | Record<string, string> | Record<string, string>[]> = {
				...setEventData,
				items,
				setTransaction,
				type: `E`
			}

			hybrid(GAData, isVirtual)
		} else {
			let GAData: Record<string, string | Record<string, string | Record<string, string>[]>> = {
				event: `ga_ecommerce`,
				...setEventData,
				ecommerce: {
					...setTransaction,
					items
				}
			}

			if (isVirtual === true) {
				GAData = {
					...virCommonData,
					...GAData
				}
			} else {
				GAData = {
					...GAData
				}
			}

			window.dataLayer.push(GAData)

			resetDataLayer(GAData)
		}
	} catch (e) {
		console.log(`sendGAEcommerce 함수 ERROR`)

		if (e instanceof Error) {
			console.log(e.message)
		}
	}
}
