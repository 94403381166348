import HeaderMenuCategoryView from '@dg/common/components/Pc/Header/HeaderView/HeaderMenu/HeaderMenuView/HeaderMenuCategoryView'
import HeaderMenuLiView from '@dg/common/components/Pc/Header/HeaderView/HeaderMenu/HeaderMenuView/HeaderMenuLiView'
import IcMove from '@dg/common/public/icons/common/ic-move.svg'
import pathsData from '@dg/common/public/json/pc/paths'
import {
	FocusEvent, MouseEvent
} from 'react'
import {
	useTranslation
} from 'react-i18next'

interface HeaderMenuViewProps {
	active1: string;
	active2: string;
	category: boolean;
	categoryUlFunc: (
		event: FocusEvent<HTMLAnchorElement | HTMLButtonElement | HTMLLIElement, Element> | MouseEvent) => void;
	title: string;
}

const HeaderMenuView = ({
	title, active1, active2, category, categoryUlFunc
}: HeaderMenuViewProps) => {
	const {
		t
	} = useTranslation()

	return (
		<nav
			className="relative z-0 mt-2 h-8.5 w-full min-w-pc bg-[#527fef]"
		>
			<ul
				className="mx-auto flex h-full w-pc"
			>
				<HeaderMenuCategoryView
					active1={active1}
					active2={active2}
					category={category}
					categoryUlFunc={categoryUlFunc}
				/>

				<HeaderMenuLiView
					link={pathsData.timeSale}
					selected={(title === t(`타임 세일`))}
				>
					{t(`타임 세일`)}
				</HeaderMenuLiView>

				<HeaderMenuLiView
					link={pathsData.dailyDeal}
					selected={(title === t(`오늘의 특가`))}
				>
					{t(`오늘의 특가`)}
				</HeaderMenuLiView>

				<HeaderMenuLiView
					link={pathsData.groupBuy}
					selected={(title === t(`공동구매`))}
				>
					{t(`공동구매`)}
				</HeaderMenuLiView>

				<HeaderMenuLiView
					link={pathsData.bestseller}
					selected={(title === t(`랭킹`))}
				>
					{t(`랭킹`)}
				</HeaderMenuLiView>

				<HeaderMenuLiView
					link={pathsData.qchance}
					selected={(title === t(`Q 라운지`))}
				>
					{t(`Q 라운지`)}
				</HeaderMenuLiView>

				<HeaderMenuLiView
					link={pathsData.directShop}
					selected={(title === t(`이베이 다이렉트 숍`))}
				>
					{t(`이베이 다이렉트 숍`)}
				</HeaderMenuLiView>

				<HeaderMenuLiView
					className="bg-dg-movePinkVariant3 dark:bg-dgdark-movePinkVariant3"
					link={pathsData.moveHome}
					selected={(title === `Move`)}
				>
					<IcMove
						className="w-15 [&>g:not(:last-child)>path]:!fill-dg-white dark:[&>g:not(:last-child)>path]:!fill-dgdark-white"
					/>

					<span
						className="sr-only"
					>
						Move
					</span>
				</HeaderMenuLiView>
			</ul>
		</nav>
	)
}

export default HeaderMenuView
