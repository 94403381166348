import {
	apiFunc
} from '@dg/common/lib/common'

interface CategoryMenusApiDataProps {
	category_cd: string;
	category_nm: string;
	filter_category_list: CategoryMenusApiDataProps[];
	group_cd: string;
	group_nm: string;
	sub_category_list: CategoryMenusApiDataProps[];
}

export interface CategoryMenusApiProps {
	code: number;
	message?: string;
	result: CategoryMenusApiDataProps[];
}

/* 카테고리 메뉴 */
const categoryMenusApi = async () => {
	const response = await apiFunc(`/api/legacy/category/menus`)

	return response.data as CategoryMenusApiProps
}

export interface SearchPopularApiProps {
	code: number;
	message?: string;
	result: {
		keyword: string;
		originalKeyword?: string;
	}[];
}

/* 인기 검색어 */
const searchPopularApi = async (length: number) => {
	const response = await apiFunc(`/api/legacy/search/popular/${length}`)

	return response.data as SearchPopularApiProps
}

export interface ItemApiProps {
	ad_point: number; // 1 - 파워랭크업
	basis_type: string; // GB - 공동구매, PD - 데일리딜, TD - 타임세일
	brand_country: string;
	cps_yn: string;
	ctg: number;
	discount_price: number;
	final_price: number;
	gd_kind: string;
	gd_nm: string;
	gd_no: string;
	gdlc_cd: string;
	gdmc_cd: string;
	group_price: number;
	img_contents_no: number;
	img_contetns_no: number;
	main_item_type: string;
	priority: number;
	promotion_no: number;
	satisfaction_percent: number;
	sell_price: number;
	succ_bid_poss_price: number;
	trad_way: string;
}

export interface SearchRelatedGoodsApiProps {
	code: number;
	message?: string;
	result: ItemApiProps[];
}

/* 키워드 연관 검색어 상품 */
const searchRelatedGoodsApi = async (keyword: string, length: number) => {
	const response = await apiFunc(`/api/legacy/search/related/${keyword}/goods/${length}`)

	return response.data as SearchRelatedGoodsApiProps
}

export {
	categoryMenusApi,
	searchPopularApi,
	searchRelatedGoodsApi
}
