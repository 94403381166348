type gradeDataProps = Record<string, Record<string, {
	colorBorder: string;
	colorFill: string;
	colorText: string;
	name: string;
}>>

const gradeData: gradeDataProps = {
	customerGrade: {
		B1: {
			colorBorder: `border border-solid border-[#000000] text-[#000000]`,
			colorFill: `bg-[#1e1e1e] text-dg-white dark:text-dgdark-white`,
			colorText: `text-[#000000]`,
			name: `Blacklist`
		},
		G1: {
			colorBorder: `border border-solid border-[#929292] text-[#929292]`,
			colorFill: `bg-[#acacac] text-dg-white dark:text-dgdark-white`,
			colorText: `text-[#929292]`,
			name: `Gray`
		},
		GUEST: {
			colorBorder: `border border-solid border-[#1e71e2] text-[#1e71e2]`,
			colorFill: `bg-[#1e71e2] text-dg-white dark:text-dgdark-white`,
			colorText: `text-[#1e71e2]`,
			name: `Guest`
		},
		N1: {
			colorBorder: `border border-solid border-[#00a651] text-[#00a651]`,
			colorFill: `bg-[#00b325] text-dg-white dark:text-dgdark-white`,
			colorText: `text-[#00a651]`,
			name: `一般`
		},
		R1: {
			colorBorder: `border border-solid border-[#069dcf] text-[#069dcf]`,
			colorFill: `bg-[#00a9e1] text-dg-white dark:text-dgdark-white`,
			colorText: `text-[#069dcf]`,
			name: `シルバー`
		},
		V1: {
			colorBorder: `border border-solid border-[#ff7200] text-[#ff7200]`,
			colorFill: `bg-[#ff8a00] text-dg-white dark:text-dgdark-white`,
			colorText: `text-[#ff7200]`,
			name: `ゴールド`
		},
		V2: {
			colorBorder: `border border-solid border-[#7514be] text-[#7514be]`,
			colorFill: `bg-[#8a20dc] text-dg-white dark:text-dgdark-white`,
			colorText: `text-[#7514be]`,
			name: `VIP`
		}
	},
	sellerGrade: {
		A1: {
			colorBorder: `border border-solid border-[#ff7200] text-[#ff7200]`,
			colorFill: `bg-[#ff8a00] text-dg-white dark:text-dgdark-white`,
			colorText: `text-[#ff7200]`,
			name: `パワー`
		},
		B1: {
			colorBorder: `border border-solid border-[#069dcf] text-[#069dcf]`,
			colorFill: `bg-[#00a9e1] text-dg-white dark:text-dgdark-white`,
			colorText: `text-[#069dcf]`,
			name: `優秀`
		},
		C1: {
			colorBorder: `border border-solid border-[#00a651] text-[#00a651]`,
			colorFill: `bg-[#00b325] text-dg-white dark:text-dgdark-white`,
			colorText: `text-[#00a651]`,
			name: `一般`
		},
		D1: {
			colorBorder: `border border-solid border-[#000000] text-[#000000]`,
			colorFill: `bg-[#1e1e1e] text-dg-white dark:text-dgdark-white`,
			colorText: `text-[#000000]`,
			name: `银行`
		},
		D2: {
			colorBorder: `border border-solid border-[#000000] text-[#000000]`,
			colorFill: `bg-[#1e1e1e] text-dg-white dark:text-dgdark-white`,
			colorText: `text-[#000000]`,
			name: `黑名单卖家`
		},
		X1: {
			colorBorder: `border border-solid border-[#000000] text-[#000000]`,
			colorFill: `bg-[#1e1e1e] text-dg-white dark:text-dgdark-white`,
			colorText: `text-[#000000]`,
			name: `Retired Dealer`
		}
	}
}

export default gradeData
