import {
	HeaderApiProps
} from '@dg/common/lib/api'

interface HeaderRightAlarmProps {
	data?: HeaderApiProps[`result`];
	type: `alarm` | `cart`;
}

const HeaderRightAlarmView = ({
	type, data
}: HeaderRightAlarmProps) => {
	const badgeClassName =
		`
			absolute top-1/2 left-1/2 -mt-3 ml-3 block h-3 min-w-3 -translate-x-1/2 -translate-y-1/2
			rounded-md bg-dg-red text-center text-3xs leading-3 text-dg-white
			dark:bg-dgdark-red dark:text-dgdark-white
		`

	const alarmChk = type === `alarm` && data && data?.new_msg_cnt > 0
	const cartChk = type === `cart` && data && data?.cart_cnt > 0

	return (
		(alarmChk === true || cartChk === true) && (
			<span
				className={badgeClassName}
			>
				{type === `alarm` && `N`}

				{type === `cart` && data?.cart_cnt}
			</span>
		)
	)
}

export default HeaderRightAlarmView
